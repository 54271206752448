<template>
  <div>
    <slot/>
  </div>
</template>

<style lang="less" scoped>
div {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 95vh;
}
</style>
