<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>

<script>
import Default from "./layouts/Default";
import Empty from "./layouts/Empty";

export default {
  components: {Default, Empty},
  computed: {
    layout() {
      return this.$route.meta.layout ? this.$route.meta.layout : 'Empty'
    }
  }
}
</script>

<style lang="less">
@import '~ant-design-vue/dist/antd.less';

body {
  background: rgb(252, 252, 252);
  max-width: 1650px;
  margin: 0 auto;
}

@silver: rgb(175, 175, 175);
.silver-color {
  color: @silver;
}

@border-radius-base: 6px;
@form-item-label-font-size: 13px;
// Layout
@layout-body-background: none;
@layout-header-background: none;
@layout-header-padding: 0 30px 0 24px;
@layout-header-height: 110px;
// Menu
@menu-highlight-color: none;
@menu-item-active-bg: none;
@menu-bg: none;
@menu-inline-submenu-bg: none;
@menu-inline-toplevel-item-height: 50px;
.ant-menu-inline {
  border: none;
}

.ant-menu {
  .header {
    pointer-events: none;
    color: @silver;
    font-weight: bold;
  }
}

.ant-menu-item-icon {
  display: inline-flex;
  width: 32px;
  height: 32px;
  background-color: @white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .12);
  border-radius: @border-radius-base;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  color: @silver;
}

.ant-menu-root > .ant-menu-item:not(.header),
.ant-menu-root > .ant-menu-submenu > .ant-menu-submenu-title {
  position: relative;
  z-index: 1;

  &:before {
    position: absolute;
    z-index: -1;
    content: '';
    top: 3px;
    left: 18px;
    right: 6px;
    bottom: 3px;
    background: @white;
    border-radius: @border-radius-base;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
    opacity: 0;
  }
}

.ant-menu-item-selected {
  font-weight: bold;
}

.ant-menu-root > .ant-menu-item-selected,
.ant-menu-root > .ant-menu-submenu-selected > .ant-menu-submenu-title {
  overflow: visible !important;
  font-weight: bold;

  &:before {
    opacity: 1 !important;
  }

  .ant-menu-item-icon {
    background: @primary-color;
    color: @white;
    box-shadow: none;
  }
}

.ant-menu-root > .ant-menu-submenu > .ant-menu-sub > .ant-menu-item,
.ant-menu-root > .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title {
  padding-left: 36px !important;

  &:before {
    display: inline-block;
    content: '';
    width: 5px;
    height: 5px;
    background: @silver;
    margin-right: 26px;
    border-radius: 50%;
  }

  &.ant-menu-item-selected:before {
    background: @primary-color;
    width: 8px;
    height: 8px;
    margin-right: 23px;
  }
}

//page heading
.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 0;
}

.ant-page-header-heading-left {
  margin: 0;
}

// Drawer
@drawer-body-padding: 44px;
@drawer-bg: rgb(252, 252, 252);
.ant-drawer-header {
  border-bottom: none;
}
.drawer-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  box-shadow: 0 10px 27px rgba(0, 0, 0, 0.1);
  padding: 16px 44px;
  background: #fff;
  z-index: 1;
}

// Table
th.ant-table-column-has-filters {
  position: relative;
  &:not(:last-child):before {
    position: absolute;
    content: '';
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background: rgb(240,240,240);
    transform: translateY(-50%);
  }
}
.dropdown-search-panel {
  padding: 8px;
  input {
    margin-bottom: 8px;
  }
}

// Card
@table-bg: none;
@table-header-bg: transparent;
@table-header-color: @silver;
@table-footer-bg: none;
@card-radius: 12px;
.ant-card {
  border: none;
  box-shadow: 0 10px 27px rgba(0, 0, 0, 0.1);

  .ant-card-head {
    border-bottom: none;
  }

  &.table .ant-card-body {
    padding: 0;

    .ant-table-pagination {
      margin-right: 16px;
    }
  }
}

// Notification
.ant-notification-notice {
  color: @white;

  .ant-notification-notice-message {
    color: inherit;
  }

  &.error {
    background: @btn-danger-bg;
  }

  &.warning {
    background: @warning-color;
  }

  &.success {
    background: @success-color;
  }
}

.ant-layout {
  min-height: 100vh;
}

.ant-layout-footer {
  font-size: 12px;
  color: @silver;
  display: flex;
  justify-content: space-between;
}

.editorjs {
  background: @white;
  border: 1px solid @border-color-base;
  border-radius: @border-radius-base;
  padding: 10px 40px;
  margin-bottom: 24px;
}
.codex-editor__redactor {
  padding-bottom: 40px !important;
}
.ce-block__content,
.ce-toolbar__content {
  max-width: unset;
}

</style>
