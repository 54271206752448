<template>
  <ConfigProvider :locale="ru_RU">
    <Layout>
      <LayoutSider width="280">
        <div class="logo"><img alt="logo" src="/img/logo.png" width="192" height="192"></div>
        <hr>
        <Skeleton active v-if="loading" style="margin: 0 20px"/>
        <Menu v-else v-model:selectedKeys="selectedKeys" v-model:openKeys="openKeys" mode="inline">
          <template v-for="menu in menus">
            <MenuItem v-if="!menu.icon && !menu.sub" class="header">
              <hr v-if="!menu.title">
              {{ menu.title }}
            </MenuItem>
            <template v-else-if="!menu.icon && menu.sub">
              <MenuItem class="header" :key="menu.url">
                {{ menu.title }}
              </MenuItem>
              <template v-for="menu in menu.sub">
                <MenuItem v-if="!menu.sub" :key="menu.url">
                  <template #icon>
                    <component :is="Icon[menu.icon]"/>
                  </template>
                  <router-link :to="menu.url">{{ menu.title }}</router-link>
                </MenuItem>
                <SubMenu  v-else :key="menu.url">
                  <template #icon>
                    <component :is="Icon[menu.icon]"/>
                  </template>
                  <template #title>{{ menu.title }}</template>
                  <template v-for="menu_sub in menu.sub">
                    <MenuItem v-if="!menu_sub.sub" :key="menu_sub.url">
                      <router-link :to="menu_sub.url">{{ menu_sub.title }}</router-link>
                    </MenuItem>
                    <SubMenu v-else :key="menu_sub.url">
                      <template #title>{{ menu_sub.title }}</template>
                      <MenuItem v-for="menu_sub_ in menu_sub.sub" :key="menu_sub_.url">
                        <router-link :to="menu_sub_.url">{{ menu_sub_.title }}</router-link>
                      </MenuItem>
                    </SubMenu>
                  </template>
                </SubMenu>
              </template>
            </template>
            <MenuItem v-else-if="!menu.sub" :key="menu.url">
              <template #icon>
                <component :is="Icon[menu.icon]"/>
              </template>
              <router-link :to="menu.url">{{ menu.title }}</router-link>
            </MenuItem>
            <SubMenu v-else :key="menu.url">
              <template #icon>
                <component :is="Icon[menu.icon]"/>
              </template>
              <template #title>{{ menu.title }}</template>
              <template v-for="menu_sub in menu.sub">
                <MenuItem v-if="!menu_sub.sub" :key="menu_sub.url">
                  <router-link :to="menu_sub.url">{{ menu_sub.title }}</router-link>
                </MenuItem>
                <SubMenu v-else :key="menu_sub.url">
                  <template #title>{{ menu_sub.title }}</template>
                  <MenuItem v-for="menu_sub_ in menu_sub.sub" :key="menu_sub_.url">
                    <router-link :to="menu_sub_.url">{{ menu_sub_.title }}</router-link>
                  </MenuItem>
                </SubMenu>
              </template>
            </SubMenu>
          </template>
        </Menu>
      </LayoutSider>
      <Layout>
        <LayoutHeader v-if="!loading" style="display: flex; justify-content: space-between; align-items: center">
          <PageHeader :title="title" :breadcrumb="{ routes }"/>
          <Popover placement="bottomRight" trigger="click">
            <Avatar :src="user.avatar" style="cursor: pointer">
              <template #icon>
                <UserOutlined/>
              </template>
            </Avatar>
            <template #title>
              {{ user.name }}<br>
              <small>{{ user.email }}</small>
            </template>
            <template #content>
              <Button type="primary" danger @click="logout" block>
                <template #icon>
                  <PoweroffOutlined/>
                </template>
                Выйти
              </Button>
            </template>
          </Popover>
        </LayoutHeader>
        <LayoutContent style="margin: 10px 30px 0 50px">
          <Skeleton active v-if="loading"/>
          <slot v-else/>
        </LayoutContent>
        <LayoutFooter><span>© greenkirin</span> <span>v{{ version }}</span></LayoutFooter>
      </Layout>
    </Layout>
  </ConfigProvider>
</template>

<script setup>
import ru_RU from 'ant-design-vue/lib/locale-provider/ru_RU';
import {
  ConfigProvider,
  Layout,
  LayoutSider,
  LayoutContent,
  LayoutHeader,
  LayoutFooter,
  Skeleton,
  Menu,
  MenuItem,
  SubMenu,
  PageHeader,
  Popover,
  Avatar,
  Button
} from "ant-design-vue";
import {ref, reactive, provide, watch, onBeforeMount} from "vue";
import {useRoute} from 'vue-router';
import { PoweroffOutlined, UserOutlined } from '@ant-design/icons-vue';
import * as Icon from '@ant-design/icons-vue';

const url = process.env.VUE_APP_API_URL
const version = $version
const loading = ref(true)
const menus = ref([])
const user = reactive({
  auth: false,
  avatar: null,
  id: null,
  name: null,
  email: null,
  role: null,
})
provide('$user', user)

onBeforeMount(async () => {
  await $axios.get('auth').then(r => {
    user.auth = true
    user.id = r.data.id
    user.name = r.data.name
    user.email = r.data.email
    user.avatar = r.data.cover
    user.role = r.data.role
  }).catch(() => {
    logout()
  })
  await $axios.get('menu').then(r => {
    menus.value = r.data
    upPageInfo(route.path)
  })
  loading.value = false
})

const logout = () => {
  $cookie.removeCookie('auth', {path: '/'})
  window.location.href = process.env.BASE_URL+'login'
}

const route = useRoute()
const selectedKeys = ref([])
const openKeys = ref([])

const title = ref('')
const routes = ref([])
const upPageInfo = (val) => {
  if (route.meta.layout !== 'Default') return

  selectedKeys.value = [route.path]
  openKeys.value = []
  let keys = ''
  route.path.split('/').map((path, key) => {
    if (key) {
      keys += '/' + path
      openKeys.value.push(keys)
    }
  })

  routes.value = []
  let len = val.split('/').length - 1
  let segment = val.split('/')
  let selectMenu = menus.value.find(m => m.url === '/' + segment[1])

  if (len === 1) {
    if (selectMenu.sub) {
      routes.value.push({breadcrumbName: selectMenu.title})
      selectMenu = selectMenu.sub.find(m => m.url === '/' + segment[1])
    }
  }

  if (len === 2) {
    routes.value.push({breadcrumbName: selectMenu.title})
    selectMenu = selectMenu.sub.find(m => m.url === '/' + segment[1] + '/' + segment[2])
    if (selectMenu.sub) {
      routes.value.push({breadcrumbName: selectMenu.title})
      selectMenu = selectMenu.sub.find(m => m.url === '/' + segment[1] + '/' + segment[2])
    }
  }

  if (len === 3) {
    routes.value.push({breadcrumbName: selectMenu.title})
    selectMenu = selectMenu.sub.find(m => m.url === '/' + segment[1] + '/' + segment[2])
    routes.value.push({breadcrumbName: selectMenu.title})
    selectMenu = selectMenu.sub.find(m => m.url === '/' + segment[1] + '/' + segment[2] + '/' + segment[3])
  }

  if(len === 4){
    routes.value.push({breadcrumbName: selectMenu.title})
    selectMenu = selectMenu.sub.find(m => m.url === '/' + segment[1] + '/' + segment[2])
    routes.value.push({breadcrumbName: selectMenu.title})
    selectMenu = selectMenu.sub.find(m => m.url === '/' + segment[1] + '/' + segment[2] + '/' + segment[3])
    routes.value.push({breadcrumbName: selectMenu.title})
    selectMenu = selectMenu.sub.find(m => m.url === '/' + segment[1] + '/' + segment[2] + '/' + segment[3] + '/' + segment[4])
  }

  title.value = selectMenu.title
}

watch(() => route.path, upPageInfo)
</script>

<style lang="less" scoped>
.logo {
  position: relative;

  img {
    display: block;
    max-width: 100%;
    max-height: 70px;
    width: auto;
    height: auto;
    padding: 0 20px;
    margin: 20px auto;
  }
}

hr {
  border: none;
  width: 100%;
  height: 1px;
  background-color: #f5f5f5;
  background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, .4), transparent);
  opacity: .25;
  margin: 20px 0;
}
</style>
