import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: checkAuth,
    meta: { layout: 'Default' }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { layout: 'Empty' }
  },
  {
    path: '/crm/users',
    name: 'Crm-Users',
    component: () => import('../views/Crm/Users.vue'),
    beforeEnter: checkAuth,
    meta: { layout: 'Default' }
  },
  {
    path: '/cms/pages/actions',
    name: 'Cms-Pages-Actions',
    component: () => import('../views/Cms/Pages/Actions'),
    beforeEnter: checkAuth,
    meta: { layout: 'Default' }
  },
  {
    path: '/cms/pages/vacancies',
    name: 'Cms-Pages-Vacancies',
    component: () => import('../views/Cms/Pages/Vacancies'),
    beforeEnter: checkAuth,
    meta: { layout: 'Default' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function checkAuth(to, from, next){
  if(!$cookie.getCookie($auth_name)) next('/login');
  else next()
}

export default router
