import {name, version} from '../package.json'
window.$version = version
window.$auth_name = name

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import {VueCookieNext} from "vue-cookie-next";
window.$cookie = VueCookieNext

import axios from 'axios'
axios.defaults.baseURL = (process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : '') + '/api/v1/'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.common['Authorization'] = `Bearer ${$cookie.getCookie($auth_name)}`

import {message, notification} from "ant-design-vue";
axios.interceptors.response.use((response) => response, (error) => {
  if (error.response) {
    notification.open({
      class: 'error',
      message: error.response.status,
      description: error.response.statusText,
    })
    throw error
  } else {
    message.error('Что-то пошло не так.')
  }
});

window.$axios = axios

createApp(App).use(router).mount('#app')
